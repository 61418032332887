import { Component, Vue } from "vue-property-decorator";

@Component({
  inheritAttrs: false,
})
export default class PasswordTextField extends Vue {
  private showedPassword = false;

  private toggleShowPassword() {
    this.showedPassword = !this.showedPassword;
  }
}
